import config from '@/services/config';

export const getUrlCategory = (category) => {
  return `${config.urlCore}/api/v1/finder/category/cover/image/${category}/`
}
export const getAssetUrl = (path) => {
  return config.urlCore + path;
};
export const isUrl = (string) => {
  let url = false;
    
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
export const getURL = (network, username, is_channel = false) => {
  const user = network === 'youtube' ? (is_channel ? `channel/${username}` : `c/${username}`) : network === 'tiktok' ? `@${username}` : network === 'snapchat' ? `add/${username}` : username
  if (network === 'linkedin') return `https://www.${network}.com/in/${username}`
  if (network === 'spotify') return `https://open.spotify.com/user/${username}`
  return `https://www.${network}.com/${user}`
};
export const urlStateExpression = (link) => {
  const url_regex = /^(?:http[s]?:\/\/|ftp:\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,63}(?:\/\S*)?$/i;
  return url_regex.test(link);
};
export const usernameByUrl = (url, domain) => {
  const split_domain = url.split(domain)
  if (split_domain.length < 2) return ''

  const wihtout_params = split_domain[1].split('/')[0]

  const username = wihtout_params.split('?')[0]
  return username
};
export const getUsernameUrlYoutube = (url) => {
  let username = ''
  let is_channel = false
  if (url.includes('/channel/')) {
    username = usernameByUrl(url, 'youtube.com/channel/')
    is_channel = true
  } else {
    username = usernameByUrl(url, 'youtube.com/user/')
  }

  return [username, is_channel]
};