const countries_json = () => import('@/libs/countries.json')
const languages_json = () => import('@/libs/languages.json')


export const getMotivationalPhrase = () => {
  const dayWeek = ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"];
  const motivational_phrases = [
    `En caso de que no te dieras cuenta hoy es ${dayWeek[new Date().getDay()]}`,
    "¿Ya hiciste ejercicio hoy?",
    "Siempre puedes empezar de nuevo",
    "Todo lo que puedes imaginar es real",
    "It always get better",
    "Hacer lo correcto trae paz y serenidad. Hazlo una y otra vez",
    "Hoy intenta hacer algo que crees que no puedes hacer",
    "La esperanza es el sueño del hombre despierto",
    "No cuentes los días, haz que los días cuenten!",
    "Keep going, Keep growing",
    "¿Ya te tomaste un café hoy?",
    "Si lo puedes soñar,  lo puedes lograr",
    "Baila como si estuvieras trabajando en home office",
    "Relájate un rato en lo que cargamos la página para ti",
    "We´re in this together",
    "Controla lo que puedes controlar y de lo demás relájate",
    "¿Ya meditaste hoy?",
    "Toma agua mientras cargamos la información por ti"
  ];

  const array_length = motivational_phrases.length - 1
  const index = Math.floor(Math.random() * array_length);

  return motivational_phrases[index]
}

export const getCountries = () => {
  return new Promise((resolve) => {
    let countries = localStorage.getItem('countries_json');
    if (!countries) {
      countries_json().then(({ default: data }) => {
        countries = data;
        localStorage.setItem('countries_json', JSON.stringify(countries))
        resolve(countries);
      });
    } else resolve(JSON.parse(countries));
  });
};

export const getCountry = (code) => {
  return new Promise((resolve) => {
    getCountries().then((countries) => {
      const country = countries.find(country => country['alpha-2'] === code.toUpperCase());
      if (country) {
        resolve(country.name);
      } else {
        resolve(null);
      }
    });
  })
};

export const getLanguage = (code) => {
  return new Promise((resolve) => {
    languages_json().then(({ default: languages }) => {
      const language = languages.find(item => { if (item.code === code) return item })
      if (language) {
        resolve(language.name)
      } else {
        resolve(code)
      }
    });
  })
};

export const print = async(orientation, filename, element, callback, format = 'pdf') => {
  element = element || document.body
  const noscript = document.getElementsByTagName('noscript')[0]
  if (noscript) noscript.remove()
  setTimeout(async () => {
    const margin = 15;
    const html2canvas = (await import('html2canvas')).default;
    html2canvas(element)
      .then(async (canvas) => {
        canvas.getContext('2d');
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        if (format === 'pdf') {
          const jsPdf = (await import('jspdf')).default;
          const pdf = new jsPdf(orientation, 'pt', [(canvas.width + (margin * 2)), (canvas.height + ((margin * 2)))]);
          pdf.addImage(imgData, 'JPG', margin, margin, canvas.width, canvas.height);
          pdf.save(`${filename}.pdf`);
          callback(true)
        } else {
          const a = document.createElement('a');
          a.href = imgData;
          a.download = `${filename}.png`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          callback(true)
        }
        const noscript = document.getElementsByTagName('noscript')[0]
        if (noscript === undefined) {
          const tag = document.createElement('noscript');
          const text = document.createTextNode("We're sorry but BrandMe Platform doesn't work properly without JavaScript enabled. Please enable it to continue.");
          tag.appendChild(text);
          const element = document.body;
          element.appendChild(tag);
        }
      });
  }, 100);
}

export const getTypeInfluencer = (category) => {
  const types = {
    'top-celebrity': 'Top Celebrity',
    'top-influencer-celebrity': 'Top Influencer / Celebridad',
    'microinfluencer': 'Micro-influencer',
    'macroinfluencer': 'Macro-influencer',
    'nanoinfluencer': 'Nanoinfluencer',
  }

  if (types[category]) return types[category]
  return ''
};

export const redirectLastRoute = (router) => {
  const last_routes = JSON.parse(localStorage.getItem('routes_visited'));
  router.push({name: last_routes[last_routes.length - 1].name, params: last_routes[last_routes.length - 1].params});
  localStorage.removeItem('steps');
  localStorage.removeItem('stepperInfo');
}
export const getUniqueIndex = () => {
  const timestamp = new Date().getTime();
  const random = Math.random().toString(36).substring(2, 10);
  return `${timestamp}${random}`;
};

export const getModelsImagine = () => {
  return [
    {value: 21, category: 'anime_ia', name: 'Anime'},
    {value: 26, category: 'portrait_ia', name: 'Portrait'},
    {value: 29, category: 'realistic_ia', name: 'Realistic'},
    {value: 27, category: 'imagine_v1', name: 'Imagine V1'},
    {value: 28, category: 'imagine_v3', name: 'Imagine V3'},
    {value: 30, category: 'imagine_v4', name: 'Imagine V4'},
    {value: 31, category: 'imagine_v4_creative', name: 'Imagine V4 (Creative)'},
    {value: 32, category: 'imagine_v4_1', name: 'Imagine V4.1'},
    {value: 33, category: 'imagine_v5', name: 'Imagine V5'},
    {value: 34, category: 'anime_v5', name: 'Anime V5'},
    {value: 122, category: 'sdxl_1_0', name: 'SDXL 1.0'}
  ]
}

export const getArtStyleImagine = () => {
  return [
    {value: null, category: null, name: 'No Style'},
    {value: 21, category: 'cyberpunk_style_ia', name: 'Cyberpunk'},
    {value: 26, category: 'neon_style_ia', name: 'Neon'},
    {value: 29, category: 'minecraft_style_ia', name: 'Minecraft'},
    {value: 29, category: 'tattoo_style_ia', name: 'Tattoo'}
  ]
}